export default [
	{
		post: 'Assistante de la communication digitale',
		type: 'Apprentissage d\'1 an',
		company: 'Rhinov décorateurs d\'intérieur - BEGLES (33) 2021-2022',
		tasks: [],
	},
	{
		post: 'Responsable de la communication digitale',
		type: 'Apprentissage d\'1 an',
		company: 'Agence web ONOKAA - PAU (64) 2020-2021',
		tasks: [],
	},
	{
		post: 'Web Designer',
		type: 'Stage d\'1 mois et Stage d\'1 mois',
		company:
      'GYMLAB Salle de sport - MERIGNAC (33) et ONOKAA Agence Web - Pau (64) 2019-2020',
		tasks: [],
	},
	{
		post: 'Chargée du développement de la stratégie marketing dans une Start\'Up',
		type: 'Apprentissage d\'1 an',
		company: 'Equ\'Idées - PAU (64) 2018-2019',
		tasks: [],
	},
	{
		post: 'Responsable de location en Agence Immobilière',
		type: 'Stages',
		company:
      'OPT’IMMO – (64) PAU et l\'Agence des Corsaires – (64) BAYONNE – 2016-2018',
		tasks: [],
	},
]
