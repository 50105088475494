<template>
  <Layout>
    <main class="m-auto p-4 pt-10 pb-16 font-rale sm:w-2/3 sm:pt-24">
      <div class="flex flex-col items-start gap-12 pb-16 md:flex-row">
        <div
          ref="lauImg"
          class="h-[200px] basis-5/12 rounded-2xl bg-main md:relative"
        >
          <img
            class="top-8 right-8 h-auto w-full rounded-lg md:absolute"
            src="../assets/images/moi-min.jpg"
            alt="Lauriane"
          >
        </div>
        <div
          ref="lauTxt"
          class="basis-7/12"
        >
          <h2 class="text-4xl font-light">
            PASSION COMMUNICATION DIGITALE
          </h2>
          <p class="mt-4 max-w-sm font-light">
            Dynamique et bienveillante, me voilà dans le monde du digital, à
            cheval entre le Web Design et le Web Marketing. Ma passion pour le
            numérique a prit le dessus et me voilà dans mes dernières années
            d’études dans ce domaine passionnant. Ici, vous découvrirez mon
            univers. Si vous souhaitez discuter, n’hésitez pas.
          </p>
          <div
            class="relative mt-32 ml-auto h-[100px] w-[200px] rounded-xl bg-main"
          >
            <img
              class="absolute bottom-8 -left-10 h-auto w-full rounded-xl"
              src="../assets/images/paysage.jpeg"
              alt="Paysage"
            >
          </div>
        </div>
      </div>
      <a
        class="m-auto mb-14 block rounded-md bg-black py-4 px-12 text-center font-semibold uppercase text-white no-underline transition-colors hover:bg-main hover:text-white sm:w-fit"
        href="https://www.youtube.com/watch?v=zmuhosN8hi8"
        target="_blank"
      >Video Youtube
      </a>

      <div class="flex flex-col items-start gap-12 pb-16 md:flex-row lg:mb-40">
        <div class="h-[200px] basis-5/12 md:relative">
          <img
            class="roundxl h-auto w-full object-contain"
            src="../assets/images/image_1-min.png"
            alt="Lauriane"
          >
        </div>
        <div class="basis-7/12">
          <p class="mt-4 max-w-sm font-light">
            Après quelques années dans le monde de l’Immobilier et du Marketing,
            me voilà dans le monde du digital pour la 3ème année. Entre Web
            Design et Communication digitale, j’agrandis jour après mes jours
            mes connaissances et mes compétences dans le domaine du digital.
          </p>
        </div>
      </div>
      <router-link
        to="/parcours-pro"
        class="m-auto mb-14 block rounded-md bg-black py-4 px-12 text-center font-semibold uppercase text-white no-underline transition-colors hover:bg-main hover:text-white sm:w-fit"
      >
        Découvrez mon profil professionnel
      </router-link>
      <Hobbies />
    </main>
  </Layout>
</template>

<script>
import Hobbies from '@/components/Hobbies'
import Layout from '@/components/Layout.vue'
import { gsap } from 'gsap'
export default {
	components: { Hobbies, Layout },
	mounted() {
		gsap.from(this.$refs.lauImg, { x: -300, opacity: 0, duration: 1 })
		gsap.from(this.$refs.lauTxt, { x: 300, opacity: 0, duration: 1 })
	},
}
</script>
