export default [
	{
		text: 'Mon parcours pro',
		to: '/parcours-pro',
	},
	{
		text: 'Mes créations',
		to: '/mes-creations',
	},
]
