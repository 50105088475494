import lauImg1 from '@/assets/images/lauriane-cheval-1.jpg'
import lauImg2 from '@/assets/images/lauriane-cheval-2.jpg'
import lauImg3 from '@/assets/images/lauriane-cheval-3.jpg'

export default [
	{
		alt: 'Lauriane Cheval 1',
		src: lauImg1,
	},
	{
		alt: 'Lauriane Cheval 2',
		src: lauImg2,
	},
	{
		alt: 'Lauriane Cheval 3',
		src: lauImg3,
	},
]
