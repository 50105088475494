<template>
  <header
    class="z-40 flex w-full items-center justify-between bg-slate-50 px-8 py-4 shadow-sm shadow-slate-200"
    :class="{ fixed: !nonFixed }"
  >
    <div
      ref="imgHeader"
      class="flex flex-col items-center justify-center"
    >
      <router-link to="/">
        <img
          class="h-auto w-48"
          src="../../assets/images/logofinal.png"
          alt="logo"
        >
      </router-link>
    </div>
    <nav class="hidden text-xl md:block">
      <ul class="flex space-x-8 font-rale">
        <li
          v-for="({ to, text }, i) in links"
          :key="i"
        >
          <router-link
            :to="to"
            class="no-underline"
          >
            {{ text }}
          </router-link>
        </li>
      </ul>
    </nav>
    <button
      v-if="!isMobileNavOpen"
      class="md:hidden"
      @click="isMobileNavOpen = true"
    >
      <Icon
        name="menu"
        class="h-6 w-6"
      />
    </button>
    <nav
      v-if="isMobileNavOpen"
      class="fixed inset-0 z-40 h-screen bg-white md:hidden"
    >
      <ul
        class="flex h-full flex-col items-center justify-center space-y-8 font-rale text-3xl font-bold"
      >
        <button @click="isMobileNavOpen = false">
          <Icon
            name="close"
            class="fixed top-12 right-8 h-6 w-6"
          />
        </button>
        <li
          v-for="({ to, text }, i) in links"
          :key="i"
        >
          <router-link
            :to="to"
            class="no-underline"
          >
            {{ text }}
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import gsap from 'gsap'
import links from './data'
import Icon from '../Icon.vue'
import { ref } from '@vue/reactivity'
export default {
	components: { Icon },
	props: {
		nonFixed: Boolean,
	},

	setup() {
		const isMobileNavOpen = ref(false)
		return {
			links,
			isMobileNavOpen,
		}
	},

	mounted() {
		gsap.from(this.$refs.imgHeader, { y: -300, opacity: 0, duration: 1 })
	},
}
</script>
