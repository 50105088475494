// import dribble from '../../assets/images/social-media/dribbble.png'
// import facebook from '../../assets/images/social-media/facebook.png'
// import insta from '../../assets/images/social-media/insta.png'
import linkedin from '../../assets/images/social-media/linkedin.png'
// import pinterest from '../../assets/images/social-media/pinterest.png'
// import snapchat from '../../assets/images/social-media/snapchat.png'
// import twitter from '../../assets/images/social-media/twitter.png'
// import whatsapp from '../../assets/images/social-media/whatsapp.png'
import youtube from '../../assets/images/social-media/youtube.png'
import behance from '../../assets/images/social-media/behance.png'

export default [
	// {
	// 	name: 'Facebook',
	// 	img: facebook
	// },
	// {
	// 	name: 'Dribble',
	// 	img: dribble
	// },
	// {
	// 	name: 'Instagram',
	// 	img: insta
	// },
	{
		name: 'Linkedin',
		img: linkedin,
		link: 'https://www.linkedin.com/in/lauriane-peynot-804905172/',
	},
	// {
	// 	name: 'Pinterest',
	// 	img: pinterest
	// },
	// {
	// 	name: 'Snapchat',
	// 	img: snapchat
	// },
	// {
	// 	name: 'Twitter',
	// 	img: twitter
	// },
	// {
	// 	name: 'WhatsApp',
	// 	img: whatsapp
	// },
	{
		name: 'Behance',
		img: behance,
		link: 'https://www.behance.net/laurianepeynot',
	},
	{
		name: 'Youtube',
		img: youtube,
		link: 'https://www.youtube.com/channel/UCM0_veJF60Gus8zquGh0Nsg',
	},
]
