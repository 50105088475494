<template>
  <div class="scrollTriggerKey flex flex-wrap justify-between gap-8 text-white">
    <h2
      v-for="(kw, i) in keywords"
      :key="i"
      class="gsap-h2 mx-auto mb-8 whitespace-nowrap text-3xl font-bold text-white lg:text-6xl"
    >
      {{ kw }}
    </h2>
  </div>
</template>

<script>
import keywords from './data'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
	setup() {
		return {
			keywords,
		}
	},

	mounted() {
		gsap.registerPlugin(ScrollTrigger)
		let exp = gsap
			.timeline()
			.from('.gsap-h2', {
				opacity: 0,
				duration: 1,
				y: 200,
				x: -100,
				ease: 'power4',
				stagger: 0.2,
			})
		ScrollTrigger.create({
			trigger: '.scrollTriggerKey',
			start: 'top 90%',
			toggleActions: 'play none none reverse',
			animation: exp,
		})
	},
}
</script>
