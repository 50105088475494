<template>
  <div
    class="scrollTriggerH grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
  >
    <div
      v-for="(hobby, i) in hobbies"
      :key="i"
      class="gsap-h flex items-center justify-center space-x-8 rounded bg-slate-50 p-8 text-black shadow-sm shadow-slate-900"
    >
      <Icon
        v-if="hobby.icon"
        :name="hobby.icon"
        class="w-3/12 text-main"
      />
      <span class="w-9/12 text-2xl font-light">{{ hobby.name }}</span>
    </div>
  </div>
</template>

<script>
import Icon from '../Icon.vue'
import hobbies from './data'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
	components: { Icon },

	setup() {
		return {
			hobbies,
		}
	},

	mounted() {
		gsap.registerPlugin(ScrollTrigger)
		let exp = gsap
			.timeline()
			.from('.gsap-h', {
				opacity: 0,
				duration: 1,
				y: 200,
				rotate: 10,
				ease: 'power4',
				stagger: 0.2,
			})
		ScrollTrigger.create({
			trigger: '.scrollTriggerH',
			start: 'top 90%',
			toggleActions: 'play none none reverse',
			animation: exp,
		})
	},
}
</script>
