<template>
  <Layout>
    <section
      class="flex items-center justify-center bg-[#e5d6b4] py-20 font-light lg:py-32"
    >
      <div class="mx-auto w-11/12 py-4 text-2xl md:w-10/12 lg:text-3xl">
        <h2 class="text-3xl font-bold text-white lg:text-6xl">
          WEB DESIGN
        </h2>
        <div
          class="scroll-design grid grid-cols-1 gap-4 pt-16 font-rale md:grid-cols-2 xl:grid-cols-3"
        >
          <div
            v-for="({ img, legend }, i) in images.webDesign"
            :key="i"
            class="flex flex-col"
          >
            <div class="flex-1">
              <img
                :src="img"
                class="gsap-d h-full w-full object-cover object-center"
                :alt="`Image web design ${i}`"
              >
            </div>
            <div class="bg-black p-2 font-rale text-lg text-white">
              {{ legend }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="flex items-center justify-center bg-white py-20 font-light lg:py-32"
    >
      <div class="mx-auto w-11/12 py-4 text-2xl md:w-10/12 lg:text-3xl">
        <h2 class="text-3xl font-bold lg:text-6xl">
          WEB MARKETING
        </h2>
        <div
          class="scroll-market grid grid-cols-1 gap-4 pt-16 font-rale md:grid-cols-2 xl:grid-cols-3"
        >
          <div
            v-for="({ img, legend }, i) in images.webMarket"
            :key="i"
            class="flex flex-col"
          >
            <div class="flex-1">
              <img
                :src="img"
                class="gsap-wm h-full w-full object-cover object-center"
                :alt="`Image web design ${i}`"
              >
            </div>
            <div class="bg-black p-2 font-rale text-lg text-white">
              {{ legend }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="flex items-center justify-center bg-main py-20 font-light lg:py-32"
    >
      <div
        class="mx-auto w-11/12 py-4 text-2xl text-white md:w-10/12 lg:text-3xl"
      >
        <h2 class="text-3xl font-bold lg:text-6xl">
          AUTRES
        </h2>
        <div
          class="scroll-other grid grid-cols-1 gap-4 pt-16 font-rale md:grid-cols-2 xl:grid-cols-3"
        >
          <div
            v-for="({ img, legend, links }, i) in images.others"
            :key="i"
            class="flex flex-col"
          >
            <div class="flex-1">
              <img
                :src="img"
                class="h-full w-full object-cover object-center"
                :alt="`Image web design ${i}`"
              >
            </div>
            <div class="bg-black p-2 font-rale text-lg text-white">
              {{ legend }}
              <ul>
                <li
                  v-for="{ title, href } in links"
                  :key="href"
                >
                  {{ title }} <a
                    :href="href"
                    target="_blank"
                  >{{ href }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout.vue'
import images from './data/creations/data'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
	components: { Layout },

	setup() {
		return {
			images,
		}
	},

	mounted() {
		gsap.registerPlugin(ScrollTrigger)
		let design = gsap.timeline().from('.gsap-d', {
			opacity: 0,
			duration: 1,
			y: 200,
			x: -100,
			ease: 'power4',
			stagger: 0.2,
		})
		let market = gsap.timeline().from('.gsap-wm', {
			opacity: 0,
			duration: 1,
			y: 200,
			x: 100,
			ease: 'power4',
			stagger: 0.2,
		})
		let others = gsap.timeline().from('.gsap-o', {
			opacity: 0,
			duration: 1,
			y: 200,
			x: -100,
			ease: 'power4',
			stagger: 0.2,
		})

		ScrollTrigger.create({
			trigger: '.scroll-design',
			start: 'top 90%',
			toggleActions: 'play none none reverse',
			animation: design,
		})
		ScrollTrigger.create({
			trigger: '.scroll-market',
			start: 'top 90%',
			toggleActions: 'play none none reverse',
			animation: market,
		})
		ScrollTrigger.create({
			trigger: '.scroll-other',
			start: 'top 90%',
			toggleActions: 'play none none reverse',
			animation: others,
		})
	},
}
</script>
