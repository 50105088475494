import HomePage from '@/pages/HomePage'
import MentionLegale from '@/pages/MentionLegale'
import ParcoursPro from '@/pages/ParcoursPro'
import Page404 from '@/pages/Page404'
import Creations from '@/pages/Creations'
import OosPage from '@/pages/OosPage'

export default [
	{ path: '/', component: HomePage },
	{ path: '/mentions-legales', component: MentionLegale },
	{ path: '/parcours-pro', component: ParcoursPro },
	{ path: '/mes-creations', component: Creations },
	{ path: '/oos', component: OosPage },
	{ path: '/:pathMatch(.*)*', component: Page404 },
]
