<template>
  <div class="mb-4 font-rale">
    <h2 class="mb-8 text-5xl font-bold">
      {{ title }}
    </h2>
    <component
      :is="img ? 'div' : type"
      v-for="({ type, text, img, reverse, src }, i) in content"
      :key="i"
      :class="{
        'mt-20 text-3xl font-bold text-black': type === 'h3',
        'mt-2 text-lg font-medium text-black': type === 'p',
        '!mt-8 mb-8 flex flex-col items-start gap-8 sm:flex-row': img,
        '!flex-row-reverse': reverse,
        'mt-8 mb-8 w-full': src,
      }"
      v-bind="{ src }"
    >
      {{ img ? "" : text }}

      <component
        :is="type"
        v-if="img"
        class="w-full sm:w-8/12"
      >
        {{ text }}
      </component>

      <img
        v-if="img"
        :src="img"
        alt="Alt"
        class="w-full sm:w-4/12"
      >
    </component>
  </div>
</template>

<script>
export default {
	props: {
		title: String,
		color: String,
		content: Array,
	},
}
</script>
