<template>
  <div
    class="scrollTrigger grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-3"
  >
    <div
      v-for="(exp, index) in exps"
      :key="index"
      class="gsap-exp rounded bg-slate-50 p-8 shadow-sm shadow-slate-400"
    >
      <h3 class="text-xl font-medium lg:text-3xl">
        {{ exp.post }}
      </h3>
      <small class="text-lg">{{ exp.type }} - {{ exp.company }}</small>
      <ul class="pl-2 text-xl font-medium text-slate-600">
        <li
          v-for="(task, i) in exp.tasks"
          :key="i"
          class="before:mr-2 before:text-slate-900 before:content-['-']"
        >
          {{ task }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import exps from './data'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
	setup() {
		return {
			exps,
		}
	},
	mounted() {
		gsap.registerPlugin(ScrollTrigger)
		let exp = gsap
			.timeline()
			.from('.gsap-exp', {
				opacity: 0,
				duration: 1,
				y: 200,
				x: -50,
				ease: 'power4',
				stagger: 0.2,
			})
		ScrollTrigger.create({
			trigger: '.scrollTrigger',
			start: 'top 90%',
			toggleActions: 'play none none reverse',
			animation: exp,
		})
	},
}
</script>
