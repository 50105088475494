<template>
  <Layout>
    <section
      v-for="({ name, content, color }, i) in oos"
      :key="i"
      class="flex flex-col items-center justify-center py-20 lg:py-32"
      :class="{
        'bg-white': color === 'white',
        'bg-main text-white': color === 'main',
        'bg-slate-100': color === 'slate',
      }"
    >
      <div class="mx-auto w-11/12 py-4 md:w-10/12">
        <OOS
          :content="content"
          :title="name"
        />
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout.vue'
import oos from './data/oos'
import OOS from '@/components/OOS/OOS.vue'
export default {
	components: { Layout, OOS },
	setup() {
		return {
			oos,
		}
	},
}
</script>
