<template>
  <Layout>
    <!-- <section class="h-screen -mt-32 flex items-center justify-center bg-slate-50">
      <h1
        ref="leadTitle"
        class="text-4xl lg:text-8xl font-light tracking-widest text-center"
      >
        Lauriane Peynot, Passion <span class="font-bold">communication digitale</span>
      </h1>
    </section> -->
    <section
      class="flex items-center justify-center bg-main py-20 font-thin text-white lg:py-32"
    >
      <div
        ref="sectionBio"
        class="mx-auto w-11/12 py-4 text-2xl md:w-10/12 lg:text-3xl"
      >
        <div class="flex flex-wrap items-center gap-8">
          <div class="mx-auto h-auto max-w-[500px] overflow-hidden rounded-xl">
            <img
              class="h-full w-full object-cover object-center"
              src="../assets/images/image_2-min.jpg"
              alt="Lau"
            >
          </div>
          <div class="w-1/2 grow">
            <h2 class="text-center text-3xl text-white lg:text-6xl">
              Lauriane Peynot, Passion
              <span class="font-bold">communication digitale</span>
            </h2>
          </div>
        </div>
      </div>
    </section>
    <section
      class="flex items-center justify-center bg-white py-20 font-light lg:py-32"
    >
      <div
        ref="sectionWork"
        class="mx-auto w-11/12 py-4 text-3xl md:w-10/12"
      >
        <h2 class="mb-8 text-3xl font-bold text-slate-800 lg:text-6xl">
          EXPERIENCES PRO
        </h2>
        <Experiences />
      </div>
    </section>
    <section
      class="flex flex-wrap items-center justify-center bg-black py-20 font-light lg:py-32"
    >
      <div class="w-11/12 md:w-10/12">
        <Keywords />
      </div>
    </section>
    <section
      class="flex items-center justify-center bg-yellow-50 py-20 font-light lg:py-32"
    >
      <div class="mx-auto w-11/12 py-4 text-3xl md:w-10/12">
        <h2 class="mb-8 text-3xl font-bold text-yellow-600 lg:text-6xl">
          FORMATIONS
        </h2>
        <Formations />
      </div>
    </section>
    <section
      class="flex items-center justify-center bg-white py-20 font-light lg:py-32"
    >
      <div class="mx-auto w-11/12 py-4 text-3xl md:w-10/12">
        <h2 class="mb-8 text-3xl font-bold lg:text-6xl">
          COMPÉTENCES
        </h2>
        <Competences />
      </div>
    </section>
    <section
      class="flex items-center justify-center bg-main py-20 font-light text-slate-50 lg:py-32"
    >
      <div class="mx-auto w-11/12 py-4 text-3xl md:w-10/12">
        <h2 class="mb-8 text-3xl font-bold lg:text-6xl">
          HOBBIES
        </h2>
        <Interests />
      </div>
    </section>
    <section class="flex items-center justify-center bg-white py-8 font-light">
      <div class="grid grid-cols-1 gap-8 md:grid-cols-3">
        <div
          v-for="(image, i) in imagesCheval"
          :key="i"
        >
          <img
            class="h-full w-full object-cover"
            :src="image.src"
            :alt="image.alt"
          >
        </div>
      </div>
    </section>
    <section
      class="flex items-center justify-center bg-main py-20 font-light text-slate-50 lg:py-32"
    >
      <OtherActivities class="w-11/12 text-3xl md:w-10/12" />
    </section>
  </Layout>
</template>

<script>
import Experiences from '@/components/Experiences'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Formations from '@/components/Formations'
import Competences from '@/components/Competences/Competences.vue'
import Interests from '@/components/Interests/Interests.vue'
import Keywords from '@/components/Keywords/Keywords.vue'
import OtherActivities from '@/components/OtherActivities'
import imagesCheval from './data/images'
import Layout from '@/components/Layout.vue'
export default {
	components: {
		Experiences,
		Formations,
		Competences,
		Interests,
		Keywords,
		OtherActivities,
		Layout,
	},

	setup() {
		return {
			imagesCheval,
		}
	},

	mounted() {
		gsap.registerPlugin(ScrollTrigger)
		gsap.from(this.$refs.leadTitle, { opacity: 0, y: 100, duration: 3 })
		gsap.from(this.$refs.sectionBio, { opacity: 0, y: 100, duration: 3 })
		gsap.from(this.$refs.sectionWork, {
			opacity: 0,
			x: -100,
			duration: 1,
			scrollTrigger: {
				el: this.$refs.sectionWork,
				toggleActions: 'play none none reverse',
				start: 'bottom 50%',
			},
		})
	},
}
</script>
