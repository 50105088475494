<template>
  <div
    class="scrollTriggerComp grid grid-cols-1 gap-8 align-top lg:grid-cols-2"
  >
    <div
      v-for="(comp, i) in comps"
      :key="i"
      class="gsap-comp rounded bg-slate-50 p-8 shadow-sm shadow-slate-400"
    >
      <div class="flex items-center space-x-2">
        <Icon
          :name="comp.icon"
          class="hidden h-0 w-0 lg:block lg:h-8 lg:w-8"
        />
        <h3 class="mb-4 text-xl font-medium lg:text-3xl">
          {{ comp.field }}
        </h3>
      </div>
      <ul class="text-2xl">
        <li
          v-for="(list, ii) in comp.list"
          :key="ii"
        >
          - {{ list }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import comps from './data'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Icon from '../Icon.vue'
export default {
	components: { Icon },

	setup() {
		return {
			comps,
		}
	},

	mounted() {
		gsap.registerPlugin(ScrollTrigger)
		let exp = gsap
			.timeline()
			.from('.gsap-comp', {
				opacity: 0,
				duration: 1,
				y: 200,
				ease: 'power4',
				stagger: 0.2,
			})
		ScrollTrigger.create({
			trigger: '.scrollTriggerComp',
			start: 'top 90%',
			toggleActions: 'play none none reverse',
			animation: exp,
		})
	},
}
</script>
