<template>
  <Layout>
    <div class="p-12">
      <h1 class="pb-4 text-xl font-bold">
        LES MENTIONS LÉGALES
      </h1>
      <p class="font-bold">
        CE SITE EST ÉDITÉ PAR LAURIANE PEYNOT
      </p>
      <p class="pb-4">
        adresse mail : contact@laurianepeynot.fr
      </p>
      <p class="font-bold">
        CE SITE EST HÉBERGÉ PAR
      </p>
      <p class="pb-4">
        OVH SAS au capital de 10 069 020 € RCS Lille Métropole 424 761 419 00045
        Code APE 2620Z N° TVA : FR 22 424 761 419 Siège social : 2 rue
        Kellermann - 59100 Roubaix - France
      </p>
      <p class="font-bold">
        CRÉATEUR DU SITE
      </p>
      <p>LAURIANE PEYNOT</p>
      <p>adresse mail : contact@laurianepeynot.fr</p>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout.vue'
export default {
	components: { Layout },
}
</script>
