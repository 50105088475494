import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

const router = createRouter({
	scrollBehavior() {
		return {
			top: 0,
		}
	},
	history: createWebHistory(),
	routes,
})

router.afterEach((to) => {
	const pageName = capitalizeFirstLetter(
		to.fullPath.replace('/', '').split('-').join(' ')
	)
	document.title = pageName
		? 'Lauriane Peynot | ' + pageName
		: 'Lauriane Peynot'
})

export { router }
