<template>
  <div
    class="fixed top-1/2 left-0 transition-transform duration-300"
    :class="open ? 'translate-x-0' : '-translate-x-[200px]'"
  >
    <div class="relative">
      <div
        class="h-[250px] w-[200px] rounded-br-2xl bg-white shadow-sm shadow-slate-200"
      >
        <img
          :src="data.qrCode"
          class="h-full w-full object-contain"
          alt="test"
        >
      </div>
      <div
        class="absolute -right-16 top-0 flex h-16 w-16 items-center justify-center rounded-tr-full rounded-br-full bg-white shadow-sm shadow-slate-200"
        @click="open = !open"
      >
        <Icon
          name="menu"
          class="h-6 w-6 text-main"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import data from './data'
import Icon from '../Icon.vue'
export default {
	components: { Icon },
	setup() {
		let open = ref(false)
		return {
			data,
			open,
		}
	},
}
</script>
