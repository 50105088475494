export default [
	{
		name: 'Équitation en compétition (17 ans)',
		icon: 'horse',
	},
	{
		name: 'Musique',
		icon: 'music',
	},
	{
		name: 'Musculation',
		icon: 'muscle',
	},
	{
		name: 'Théâtre (3 ans)',
		icon: 'theater',
	},
	{
		name: 'Voyages',
		icon: 'travel',
	},
	{
		name: 'Football',
		icon: 'foot',
	},
	{
		name: 'Natation / Running / Skateboard',
		icon: 'run',
	},
]
