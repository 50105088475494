import fiftyOne from '@/assets/images/creations/web-design/551.png'
import BGPdepliant from '@/assets/images/creations/web-design/BGPdepliant.png'
import createurdebeaute from '@/assets/images/creations/web-design/createurdebeaute.png'
import LNT from '@/assets/images/creations/web-design/LNT.png'

import girondin from '@/assets/images/creations/web-market/girondin.png'
import Kult_print from '@/assets/images/creations/web-market/Kult_print.png'
import loucoud from '@/assets/images/creations/web-market/loucoud.png'
import tier from '@/assets/images/creations/web-market/tier.png'

//import logo from '@/assets/images/creations/autres/logo.png'
import rhinov from '@/assets/images/creations/autres/rhinov.png'
import scev1 from '@/assets/images/creations/autres/scev1.png'
import scev2 from '@/assets/images/creations/autres/scev2.png'

export default {
	webDesign: [
		{
			img: fiftyOne,
			legend:
        'Proposition de logo pour la création d’un salon dans le cadre de mes études en Web Design',
		},
		{
			img: BGPdepliant,
			legend:
        'Proposition de brochure de présentation dans le cadre de mes études en Web Design',
		},
		{
			img: createurdebeaute,
			legend: 'Création de site internet en tant que projet de fin d’étude',
		},
		{
			img: LNT,
			legend: 'Création de maquette cadre de mes études en Web Design',
		},
	],
	webMarket: [
		{
			img: girondin,
			legend:
        'Proposition de Brand Content pour promouvoir le parrainage des Girondin de Bordeaux – Exercice d’application en Mastère 2',
		},
		{
			img: Kult_print,
			legend:
        'Plan d’action de communication stratégique pour démocratiser la culture sur Bordeaux – Compétition en Mastère 2',
		},
		{
			img: loucoud,
			legend:
        'Proposition d’un plan d’action sur une Marketplace pour une boutique indépendante',
		},
		{
			img: tier,
			legend:
        'Proposition d’une ligne éditoriale pour l’entreprise Tier - Exercice d’application en Mastère 2',
		},
	],
	others: [
		//logo,
		{
			img: rhinov,
			legend:
        'Rédaction d’articles chez Rhinov pour ma dernière année de Master :',
			links: [
				{ title: 'Salon Cocooning Naturel :', href: 'https://bit.ly/3aujkDI' },
				{ title: 'Déco WC nature :', href: 'https://bit.ly/3AziHDF' },
				{ title: 'Déco salle à manger cosy :', href: 'https://bit.ly/3PfScY5' },
			],
		},
		{
			img: scev1,
			legend: '1ère Maquette pour le Centre équestre d’Abidos',
		},
		{
			img: scev2,
			legend: 'Création du site internet du Centre Equestre d’Abidos :',
			links: [{ href: 'https://www.centreequestreabidos.com' }],
		},
	],
}
