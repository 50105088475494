<template>
  <div>
    <h4 class="mb-4 font-bold">
      ACTIVITES EXTERIEURES
    </h4>
    <ul>
      <li
        v-for="(oa, i) in otherActivities"
        :key="i"
        class="font-thin"
      >
        {{ oa }}
      </li>
    </ul>
  </div>
</template>

<script>
import otherActivities from './data'
export default {
	setup() {
		return {
			otherActivities,
		}
	},
}
</script>
