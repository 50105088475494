import muscu from '../../assets/images/hobbies/musculation.svg'
import foot from '../../assets/images/hobbies/foot.svg'
import musique from '../../assets/images/hobbies/musique.svg'
import equitation from '../../assets/images/hobbies/equitation.svg'

export default [
	{
		name: 'Musique',
		logo: musique,
	},
	{
		name: 'Musculation',
		logo: muscu,
	},
	{
		name: 'Equitation',
		logo: equitation,
	},
	{
		name: 'Football',
		logo: foot,
	},
]
