export default [
	{
		field: 'WEB MARKETING & COMMUNICATION DIGITALE',
		list: [
			'SEO Technique et Sémantique',
			'Community Management',
			'Google Ads & Analytics',
			'Campagne emailing via CRM',
			'Google Tag Manager',
			'Wordpress / Elementor',
			'Prestashop',
		],
		icon: 'book',
	},
	{
		field: 'COMMUNICATION / COMMERCIALE',
		list: [
			'Relations BtoB',
			'Relations BtoC',
			'Accueil clientèle',
			'Négociation commerciale',
			'Prospection',
		],
		icon: 'communication',
	},
	{
		field: 'WEB DESIGN',
		list: [
			'Création de supports de communication',
			'Élaboration chartes graphiques',
			'Création d\'illustrations',
			'Création de maquette de site internet',
			'Connaissances UX',
			'Suite Adobe',
			'Montage vidéo',
			'Motion Design',
			'Photographie',
			'Bureautique (TOSA)',
			'Notions 3D',
		],
		icon: 'photo',
	},
	{
		field: 'DEVELOPPEMENT',
		list: ['HTML 5', 'CSS 3', 'Notions JS / PHP'],
		icon: 'code',
	},
]
