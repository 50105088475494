import img1 from '@/assets/images/oos/1.png'
import img2 from '@/assets/images/oos/2.png'
import img3 from '@/assets/images/oos/3.png'
import img4 from '@/assets/images/oos/4.png'
import img5 from '@/assets/images/oos/5.png'
import img6 from '@/assets/images/oos/6.png'
import img7 from '@/assets/images/oos/7.png'
import img8 from '@/assets/images/oos/8.png'
import img9 from '@/assets/images/oos/9.png'
import img10 from '@/assets/images/oos/10.png'

export default [
	{
		name: 'SEO LOCAL',
		color: 'main',
		content: [
			{
				type: 'h3',
				text: 'Présentation du contenu :',
			},
			{
				type: 'p',
				text: 'Lors d’un webinair présenté le 17 septembre 2021, le directeur marketing de l’agence 1min30 présente le sujet, accompagné de Stéphane Jambu, ingénieur en informatique et e-commerçant. Il se présente comme accompagnateur auprès de ses clients afin qu’ils atteignent leurs objectifs de croissance. Ces deux personnes nous parlent aujourd’hui de SEO local. Ce webinair accessible sur YouTube sous le nom «SEO local :  une stratégie SEO local optimisée en 2 points ! » et dure 1h.',
				img: img1,
				reverse: false,
			},
			{
				type: 'h3',
				text: 'Présentation de l’entreprise :',
			},
			{
				type: 'p',
				text: 'L’agence 1min30 est présentée comme la 1ère agence Inbound Marketing en France. Ils œuvrent aujourd’hui pour des pratiques marketing et commerciales plus responsables et collectives. Ainsi, ils proposent des stratégies innovantes aux entreprises partenaires pour leur permettre d’atteindre leurs objectifs.',
				img: img2,
				reverse: true,
			},
			{
				type: 'h3',
				text: 'Présentation du sujet :',
			},
			{
				type: 'p',
				text: 'Le webinair présente ainsi les différents aspects du SEO Local ainsi que ses enjeux. En effet, le SEO local est le principe d’intégrer des données géographiques pour favoriser la proximité entre les clients et les entreprises. Cette tendance est de plus en plus marquée au fil des années, suivant l’envie des consommateurs de consommer plus localement. Il est présenté comme une partie légitime du référencement naturel. Le SEO quant à lui, représente l’ensemble des pratiques permettant à un site internet d’être bien positionné dans les résultats d’une recherche Google sur un sujet précis. ',
			},
			{
				type: 'h3',
				text: 'Présentation du Webinair :',
			},
			{
				type: 'p',
				text: `Le SEO local commence par des techniques de SEO classiques pour obtenir de bons positionnement suites à des requêtes locales. Les requêtes locales représentent 25% des recherchent globales.
        M. Jambu présente ensuite les bases du SEO local avec une technique classique qui sera se concentrer sur une requête locale : «mot clé + ville / code postal / département…» 
        Cette technique semble bien fonctionner pour ceux qui ont l'opportunité d’être bien référencés car «50% des recherches locales contribuent à une visite de l’internaute»
        Un autre point à prendre dans la stratégie, c’est le «pack Google», qui en plus de référencer les sites, récente aussi sur la droite, les différents résultats avec leur position géographique, les avis, les horaires… C’est un peu comme une comparaison des fiches. `,
			},
			{
				type: 'p',
				text: 'Le but est de répondre aux intentions des internautes. Pour cela, il faut que les entreprises aient une cohérence dans leur positionnement et de leurs offres. Pour le SEO local, un outil primordial est « Google My Business », permettant de faire apparaître des informations primordiales sur des entreprises : coordonnées, adresse, photos, présentation et connexion avec Google Maps. De plus, il permet de collecter des avis sur l’entreprise, négatifs comme positifs et permet aux entreprises d’y répondre. En plus d’être utile pour le SEO local, une page GMB est primordiale pour la e-reputation. ',
			},
			{
				type: 'p',
				text: 'Avis positifs : Ils sont primordiaux à contrôler. Les avis négatifs peuvent très rapidement faire baisser la moyenne globale des avis d’une entreprise. Il faut ainsi les éviter au maximum, et préparer une stratégie de réponse.',
			},
			{
				type: 'p',
				text: 'Du contenu par ville : Certains sites dupliquent un contenu travaillé sur une ville et le duplique en changeant seulement le nom de la. Ville. Cette méthode n’est à appliquer seulement pour ceux qui n’ont pas de temps et d’argent. ',
			},
			{
				type: 'p',
				text: 'Pour ceux qui auraient un peu plus de moyen, le plus efficace serait de prendre un réacteur afin d’avoir des pages bien optimisées et différentes pour chaque ville. Il existe aussi des plateformes comme « spintiger » qui rédigent des contenus différents en changeant simplement quelques tournures de phrases et utilise des synonymes. Cette technique s’appelle le « spin de contenu ». Pour être référencé sur différentes villes, cela semble être une bonne solution moins coûteuse, surtout pour les entreprises qui voudraient être référencées sur des milliers de villes. A l’aide de variables présentent sur un texte principal, les autres textes sont générés par la suite avec des termes différents là où se trouvent ces variables.',
			},
			{
				type: 'p',
				text: 'Le Spin de contenu est une technique à la base dite « black hat » (qui ne respectent pas les règles de l’art Google) mais qui évolue constamment pour devenir plus sûre. Le spin de contenu devient aujourd’hui un travail de rédacteur web + d‘expert en SEO technique. Cette nouvelle technique ne possède que très peu d’années de recul et reste encore un peu fragile. Il faut ainsi travailler les autres aspects du SEO. D’après nos animateurs, le Spin de contenu a fait ses preuves et de nombreuses entreprises ont vu leur site web gagner en visibilité grâce à cela sur des villes ciblées.',
				img: img4,
				reverse: false,
			},
			{
				type: 'h3',
				text: 'Point de vue sur le sujet :',
			},
			{
				type: 'p',
				text: 'Si aujourd’hui j’ai souhaité regarder cette vidéo, c’est avant tout pour approfondir mes connaissances dans l’univers passionnant du SEO. Cet aspect de la stratégie web marketing est aujourd’hui primordial pour tous ceux qui souhaitent obtenir un site internet bien référencé sur le web. Cependant, le référencent naturel est complexe et je pense qu’il demande un intérêt et une veille récurrente afin de se tenir au courant des bonnes pratiques. De plus, il est possible que dans le futur, je sois amenée à travailler dans une entreprise locale ou accompagner des entreprises dans un besoin de se développer à l’échelle locale. Il est donc important de développer mes connaissances sur le thème du SEO de proximité.',
			},
			{
				type: 'p',
				text: 'Ainsi dans ce webinair, j’ai pu revoir des aspects que je connaissais, comme les bases du SEO local et le principe d’une page Google My Business.',
			},
			{
				type: 'p',
				text: 'Cependant j’ai pu découvrir le principe du « spin de contenu » que je ne connaissais pas du tout. Cette nouvelle technique semble avoir des avantages pour les entreprises qui cherchent à produire plein de contenus assez similaires pour le référencement local dans différentes villes, sans perdre trop de temps ou que cela ne devienne trop cher. Cela semble aussi être un bon moyen d’éviter le plagiat. Cependant, étant donné que le SEO est en perpétuel évolution, cette nouvelle méthode ne sera peut-être plus valable dans quelques années, malgré une volonté de la rendre de plus en plus sûre et adaptée aux exigences de Google.',
			},
		],
	},
	{
		name: 'WEB 3',
		color: 'white',
		content: [
			{
				type: 'h3',
				text: 'Présentation du contenu :',
			},
			{
				type: 'p',
				text: 'Le contenu que je souhaitais étudier ce jour-là fût un Podcast proposé sur Spotify, présent sur une chaîne qui s’appelle « Marketing Square : Les secrets du Marketing ». Nous pouvons y trouver de nombreux épisodes (+ de 130) sur de nombreux sujets liés au Marketing Digital. Les sujets sont divers, touchant autant des sujets de social média, comme du branding, de la stratégie globale ou du SEO… Les audios font entre 10 et 40 min.',
			},
			{
				type: 'img',
				src: img5,
			},
			{
				type: 'h3',
				text: 'Présentation de l’entreprise :',
			},
			{
				type: 'p',
				text: 'Les épisodes de « Marketing square » sont proposés par Caroline Mignaux, Groowth Manager et CEO de Renchmaker. Elle propose un épisode toutes les semaines avec des invités pour parler du Marketing, de la Vente et de l’Entrepreneuriat. Le but étant de proposer des méthodes actionnables, avec des ressources concrètes et des outils efficaces.',
				img: img6,
				reverse: false,
			},
			{
				type: 'p',
				text: 'Pour cet épisode, Caroline reçoit David Klingbeil, qui travaille dans le service SEA de Google USA. Après avoir créé 2 start’up dans le web du luxe et du e-commerce, il vit depuis 4 ans à Miami pour travailler chez Google. David a pu suivre l’évolution de Web1 et Web2 et en s’intéressant à l’univers e la cryptomonnaie, il s’est ‘autant plus penché à web3. ',
			},
			{
				type: 'h3',
				text: 'Présentation du sujet :',
			},
			{
				type: 'p',
				text: 'WEB3 représente selon David un ensemble de notion nouvelles, regroupant les NFT’s, la cryptomonnaie, la blockchain et le metaverse. Le WEB3 regroupe ainsi des sujets marketing, culturels autour de ces termes qui ont pris de l’ampleur depuis quelques années.',
			},
			{
				type: 'p',
				text: 'Pour retourner en arrière, le web1 représentait la lecture de contenu, il y’a une 20aine d’année. Le web 2.0 représentait du contenu généré par les utilisateurs avec Facebook, Myspace etc…',
			},
			{
				type: 'h3',
				text: 'Présentation du Podcast :',
			},
			{
				type: 'p',
				text: 'Ainsi le WEB3 présente les caractéristiques du Web1 et du Web2, permettant de lire et de créer du contenu (tweets, vidéos YouTube…). La plus grande évolution avec WEB3 c’est qu’on peut maintenant être propriétaire de contenu digital. Cette technologie s’apparente à la « BlockChain ».',
			},
			{
				type: 'p',
				text: 'Le Web 3 a ainsi permis de créer des contenus uniques, ayant de la valeur et qu’on peut posséder. On possède ainsi la valeur de ce que l’on créé.',
			},
			{
				type: 'p',
				text: '« L’économie des créateurs » regroupe tous les créateurs quels qu’ils soient et permettent avec WEB3 d’avoir une vraie structure, et d’être maître et détenteur de leurs créations. Pour les nouveaux artistes, WEB3 et les NFT’s vont les aider à gagner en visibilité sans perdre la propriété de leurs œuvres. ',
			},
			{
				type: 'p',
				text: 'Comment fonctionne les NFT’s ? Un artiste propose un contenu digital et décide de mettre une NFT dessus. Il recevra ainsi un titre de propriété qui sera inscrit sur la BlockChain, de manière publique. Si cet artiste décide de céder cette œuvre, il doit ainsi donner le titre de propriété et le changement de propriétaire se fera ainsi sur la BlockChain. Avec WEB3 aujourd’hui, il existe des contrats intelligents permettant à un artiste ayant vendu son œuvre il y a quelques années, de récupérer un % si celle-ci a pris de la valeur après la vente. ',
			},
			{
				type: 'p',
				text: 'Les NFT sont aussi possibles pour du « ticketing » permettant de vendre des places pour un événement Discord par exemple. ',
			},
			{
				type: 'p',
				text: 'De plus dans WEB3, on parle des nouvelles expériences immersives, avec l’arrivée du Métaverse. Il n’y pas de véritable définition du metaverse. Certains cependant l’apparentent à un monde virtuel avec la réalité virtuelle … Mais David caractérise plutôt cela comme étant un moment où ta vie virtuelle a plus de valeur que ta vie réelle. Avec cette arrivée du Metaverse, les marques se demandent comment elles doivent changer leur manière de promouvoir leur produit. L’une des manières serait de proposer des avatars ou images en NFT qui représenteraient la marque, avec un prix associé. Les personnes du milieu du luxe se reconnaîtront ainsi avec ces contenus. Pareil pour la vente de contenu donnant accès à des évènements privés. ',
			},
			{
				type: 'p',
				text: 'Seulement, le WEB 3 possède encore ses limites. Récemment, un artiste vendait des interprétations des sacs Hermès et les a vendues en tant que NFT. Celui-ci s’est fait des milliers de dollars. Actuellement, Hermès est en procès avec ce créateur car elle refuse de se faire voler sa propriété intellectuelle. Ce genre de cas va amener à créer des règles et des lois pour protéger les créateurs initiaux.',
				img: img7,
				reverse: false,
			},
			{
				type: 'p',
				text: 'De plus, pour garder le lien entre les marques et les créateurs de NFT, ces plateformes font le lien et permettent aux entreprises de posséder des infos ainsi qu’un contact sur l’acheteur.',
			},
			{
				type: 'h3',
				text: 'Point de vue sur le sujet :',
			},
			{
				type: 'p',
				text: 'De moins de vue, je ne connaissais que très peu de chose sur l’univers des NFT, crypto … Et je n’avais que très peu entendue parler de WEB3. Ce podcast m’a permis de comprendre un peu plus ces nouveaux domaines. ',
			},
			{
				type: 'p',
				text: 'Concernant les NFT, j’ai été très retissante à cet univers qui prend de plus en plus de place et dont on entend de plus en plus parler. Avoir écouté un spécialiste du sujet m’a permis de comprendre différemment les enjeux et les limites, mais aussi tout ce qui en été associé. J’ai ainsi pu comprendre que les NFT’s n’en sont qu’à leur début, que cela se met en place dans le temps. ',
			},
			{
				type: 'p',
				text: 'Enfin, j’ai pu comprendre les enjeux de la propriété digitale pour les marques, bien que ce Podcast ne soit centré que sur le luxe, dans ces NFT. En effet, la propriété digitale peut être suivi par les marques, et Web3 va permettre aux entreprises de garder un lien avec les consommateurs, qui serait dérangé avec cette nouvelle manière de consommer. Comment les entreprises pourront ainsi proposer du contenu innovant qui intéressera le consommateur, que cela soit en NFT ou dans le metaverse très prochainement ?',
			},
		],
	},
	{
		name: 'SEO Technique : Indéxation',
		color: 'slate',
		content: [
			{
				type: 'h3',
				text: 'Présentation du contenu :',
			},
			{
				type: 'p',
				text: `Le 3eme Out Of School que je souhaite présenter est une formation en 
        ligne proposée par mon entreprise via le logiciel que nous utilisons pour 
        tous nos travaux de SEO : SmartKeyword. Cette formation mise en ligne 
        depuis quelques mois est
        composée de plusieurs chapitres
        mélangeant contenu
        rédactionnel, vidéos ainsi qu’un
        quizz final pour valider les connaissances apprises durant cette formation. `,
				img: img8,
				reverse: false,
			},
			{
				type: 'h3',
				text: 'Présentation du Sujet :',
			},
			{
				type: 'p',
				text: 'Il existe plusieurs thématiques allant des bases du SEO, à la partie rédactionnelle, en passant par le SEO technique et une partie appliquée au e-commerce. Ainsi, j’ai voulu travailler et présenter le SEO Technique, et plus spécifiquement « l’Indexation ». Cette formation de 40min permet de reprendre toutes les bases de ce thème.',
			},
			{
				type: 'h3',
				text: 'Qui est SmartKeyword ?',
			},
			{
				type: 'p',
				text: 'Smartkeyword est, comme présenté plus haut, une plateforme spécialisée dans le SEO et l’accompagnement en BtoB. Grâce à leurs équipes de poitures dans ce domaine, ils aident au quotidien les entreprises à améliorer leur positionnement sur les moteurs de recherches, d’un point de vue On-page mais aussi Off-page. Un accompagnement personnalisé en fonction des entreprises est mis en place avec des formations ainsi que des échanges avec un référent de SmartKeyword. Les outils sont simples à prendre en main, que cela soit pour la recherche de mots clefs, à l’amélioration des pages d’un site web sur tous les aspects. ',
			},
			{
				type: 'h3',
				text: 'Sujet :',
			},
			{
				type: 'p',
				text: `Le SEO local commence par des techniques de SEO classiques pour obtenir de bons positionnement suites à des requêtes locales. Les requêtes locales représentent 25% des recherchent globales.
        M. Jambu présente ensuite les bases du SEO local avec une technique classique qui sera se concentrer sur une requête locale : «mot clé + ville / code postal / département…» 
        Cette technique semble bien fonctionner pour ceux qui ont l'opportunité d’être bien référencés car «50% des recherches locales contribuent à une visite de l’internaute»
        Un autre point à prendre dans la stratégie, c’est le «pack Google», qui en plus de référencer les sites, récente aussi sur la droite, les différents résultats avec leur position géographique, les avis, les horaires… C’est un peu comme une comparaison des fiches.`,
				img: img3,
				reverse: false,
			},
			{
				type: 'p',
				text: 'Au commencement, on nous présente ce que signifie l’indexation. Ainsi, cela représente une petite partie de ce qu\'il est nécessaire de contrôler lorsque l\'on réalise un audit technique, mais elle est primordiale.',
			},
			{
				type: 'p',
				text: 'Ensuite, on apprend les règles du « noindex et nofollow » qu’il faut placer dans le code source des pages. Cela sert à montrer aux robots de Google que les pages présentants ce bout de code de doivent pas être indexées et que les liens présents ne doivent pas être suivis. ',
			},
			{
				type: 'p',
				text: 'Après, on nous apprend l’utilité du fichier « robots.txt », qui permet d\'indiquer aux robots des moteurs de recherches Google les zones à ne pas explorer : Pages web ou répertoires complets. Il est important que chaque site dispose de ce fichier. Le meilleur moyen pour le trouver et de taper « monsiteweb.com/robots.txt ». S’il existe, il faut vérifier que les pages sont toutes correctes, bloquées ou non bloquées.',
				img: img9,
				reverse: true,
			},
			{
				type: 'p',
				text: 'En 3ème partie, nous avons vu la partie « sitemap ». Ce fichier XML présente les urls que l\'on souhaite faire connaître auprès de Google. Il permet de hiérarchiser les ressources et les contenus proposés par le site et contient pour chaque URL des informations complémentaires, comme les dates et la fréquence des mises à jour ou l\'importance d\'une page par rapport aux autres. Pour analyser le sitemap, on peut le trouver grâce à « Google Search Console ». Sinon, on peut le rechercher grâce à « monsiteweb.com/sitemap.xml », mais cela n’est pas toujours fiable. ',
			},
			{
				type: 'p',
				text: 'Google Search Console est un service de Google qui permet de vérifier l\'état d\'indexation d’un site et de suivre l\'apparition sur les résultats de recherches. Il propose deux fonctionnalités liées à l’indexation : la couverture de l’index et sitemap.xml.',
			},
			{
				type: 'p',
				text: 'La couverture de l’index représente les pages valides, les pages d’erreurs et les pages exclues.',
			},
			{
				type: 'p',
				text: 'Google search Console permet aussi d’identifier les pages qui sont bien indexées, et celles qui ne sont pas présentes sur le sitemap. Cela permet de se rendre compte si le sitemap est à jour ou non.',
				img: img10,
				reverse: false,
			},
			{
				type: 'p',
				text: 'Après, on apprend le principe des URL’s avertissements. Elles représentent les url’s indexées malgré le blocage par le fichier robots.txt. Il faut ainsi les identifier et les désindexer proprement.',
			},
			{
				type: 'p',
				text: 'Par la suite, on nous explique comment identifier les URL’s en erreurs, c\'est-à-dire celles que Google n\'a pas indexées car présentant des erreurs. Il existe plusieurs formes d’erreurs, par exemple : contient une directive « noindex », erreur de serveur, mauvaise redirection ou encore url envoyé introuvable (404). Pour régler cela, il suffit de corriger l’erreur.',
			},
			{
				type: 'p',
				text: 'Enfin, on apprend dans cette formation les URL exclues, qui représentent des url’s que Google n\'a pas indexer, jugeant cela volontaire. Il s\'agit d\'URLs que l’on a n’a pas choisi d\'envoyer à Google via un sitemap, ce pourquoi il ne peut pas préjuger que c\'est une erreur. Les causes sont nombreuses : bloquée en raison d’une demande non autorisée, introuvable, soft 404 (introuvable) mais aussi des pages en doubles, page supprimé en raison d’une réclamation légale …. Les URL’s exclues proviennent aussi d’un balisage « noindex », bloquée par le fichier robots.txt. Il arrive parfois que l’exploration soit simplement reportée.',
			},
			{
				type: 'h3',
				text: 'Point de vue sur le sujet',
			},
			{
				type: 'p',
				text: 'Après avoir suivi quelques formations proposées sur mon temps libre, la partie SEO technique est celle qui m’a le plus intéressée. En effet, c’est celle que je maitrisais le moins, et cette formation m’a permis de développer mes compétences sur le sujet. Le SEO technique ou le SEO de manière globale, est en constante évolution, ce qui le rend à mon sens, passionnant.',
			},
			{
				type: 'p',
				text: 'Malgré ma présentation de la partie « indexation », le SEO technique englobe aussi d’autres sujets comme les « cocons sémantiques, l’architecture technique, … ».',
			},
			{
				type: 'p',
				text: 'Cette partie indexation m’a permis de revoir les principes d’un sitemap, d’un fichier robots.xt mais m’a surtout permis de savoir lire correctement la Google Search Console et comment régler les soucis liés aux pages en erreurs et exclues. Cela m’aidera pour mon futur projet professionnel pour devenir une pointure du SEO. Il faudra quand même que je garde un œil constant afin d’être au courant de la moindre modification des techniques utilisées pour améliorer le référencement naturel. ',
			},
			{
				type: 'p',
				text: 'Maintenant, il ne me reste qu’à suivre les prochaines formations !',
			},
		],
	},
]
