<template>
  <Header />
  <div class="flex min-h-screen flex-col bg-slate-50 pt-32">
    <div class="grow">
      <slot />
    </div>
    <Footer class="border-t-2 border-slate-200 bg-slate-100" />
  </div>
  <QRCode />
</template>

<script>
import Footer from './Footer'
import Header from './Header'
import QRCode from './QRCode/QRCode.vue'
export default {
	components: { Header, Footer, QRCode },
}
</script>
