<template>
  <div class="p-4">
    <div class="mx-auto flex w-1/2 flex-wrap items-center justify-around py-4">
      <a
        v-for="(social, index) in socialMedia"
        :key="index"
        :href="social.link"
        class="block"
        target="_blank"
      >
        <img
          class="mx-auto h-16 w-16"
          :src="social.img"
          :alt="social.name"
        >
      </a>
    </div>
    <div
      class="mx-auto flex w-1/2 flex-wrap items-center justify-center py-4 font-light"
    >
      <a
        class="mx-4 my-2"
        href="#"
      >Plan du site</a>
      <router-link
        class="mx-4 my-2"
        to="/mentions-legales"
      >
        Mention légales
      </router-link>
      <span class="mx-4 my-2">Lauriane Peynot</span>
    </div>
  </div>
</template>

<script>
import socialMedia from './social-media'
export default {
	setup() {
		return {
			socialMedia,
		}
	},
}
</script>
