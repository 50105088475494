<template>
  <div class="m-auto grid grid-cols-1 pt-16 sm:grid-cols-2 lg:grid-cols-4 align-middle">
    <div
      v-for="({ name, logo }, index) in hobbies"
      :key="index"
      class="m-auto mt-8 flex flex-col items-center justify-center font-light"
    >
      <img
        class="mb-4 h-20 opacity-90"
        :src="logo"
        :alt="name"
      >
      <span class="bottom-20">{{ name }}</span>
    </div>
  </div>
</template>

<script>
import hobbies from './hobbies'
export default {
	setup() {
		return {
			hobbies,
		}
	},
}
</script>
