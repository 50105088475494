<template>
  <Layout>
    <div class="p-12">
      <h1 class="my-4 text-5xl font-bold">
        Erreur 404
      </h1>
      <p>
        Page non trouvée ! Vous pouvez
        <router-link to="/">
          revenir sur la page d'accueil
        </router-link>
      </p>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout.vue'
export default {
	components: { Layout },
}
</script>
