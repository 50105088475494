<template>
  <div
    class="scrollTriggerForm grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-3"
  >
    <div
      v-for="(form, i) in forms"
      :key="i"
      class="gsap-form rounded bg-white p-8 shadow-sm shadow-yellow-500"
    >
      <h3 class="text-xl font-medium text-yellow-600 lg:text-3xl">
        {{ form.name }}
      </h3>
      <div class="mb-4 font-semibold">
        {{ form.date }}
      </div>
      <div class="text-lg lg:text-2xl">
        {{ form.degree }}
      </div>

      <ul class="text-sm lg:text-lg">
        <li
          v-for="(more, ii) in form.more"
          :key="ii"
        >
          - {{ more }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import forms from './data'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
	setup() {
		return {
			forms,
		}
	},

	mounted() {
		gsap.registerPlugin(ScrollTrigger)
		let exp = gsap
			.timeline()
			.from('.gsap-form', {
				opacity: 0,
				duration: 1,
				y: 200,
				x: 50,
				ease: 'power4',
				stagger: 0.2,
			})
		ScrollTrigger.create({
			trigger: '.scrollTriggerForm',
			start: 'top 90%',
			toggleActions: 'play none none reverse',
			animation: exp,
		})
	},
}
</script>
