export default [
	{
		name: 'ECV Digital BORDEAUX',
		date: '2020-2022',
		degree: 'Mastère Marketing et Communication Digitale',
		// more: [
		// 	'Stratégie Web Marketing : E-commerce, Contenu, Media planning et régie, Gestion de projet, CRM',
		// 	'Stratégie d\'acquisition, de notoriété et de fidélisation : SEO, SEA, Data Analyse, E-mailing',
		// 	'Stratégie Social Media Stratégie d’influence, Social Ads, Social Selling, Communication de crise, Influence'
		// ]
	},
	{
		name: 'GRETA CFA Aquitaine PAU',
		date: '2019-2020',
		degree: 'Titre Professionnel en Web Design',
		more: [],
	},
	{
		name: 'Talis BS à BAYONNE',
		date: '2018-2019',
		degree: 'Bachelor Responsable de développement Marketing / Vente',
		more: [],
	},
	{
		name: 'Institut St Dominique PAU',
		date: '2016-2018',
		degree: 'BTS Professions Immobilières',
		more: [],
	},
]
